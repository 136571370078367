<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="场地名称：">
          <el-input v-model="listQuery.Name" placeholder="请输入场地名称" class="input-width"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
          <el-button @click="addOrEidtClubSite()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加场地</span></div> -->
            添加场地
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体内容 -->
    <main class="page-main-main" ref='main'>
      <!--表格渲染-->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="场地名称" prop="Name" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="可容纳人数" prop="MemberCount" align="center" width="100px" />
        <el-table-column label="训练地址" prop="Address" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="备注" prop="Remarks" align="center">
          <template slot-scope="{row}">
            <el-tooltip effect="dark" placement="top">
              <div v-html="row.Remarks" slot="content"></div>
              <div class="oneLine">{{row.Remarks}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="addOrEidtClubSite(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubSite(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页部分 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubSiteList" />
      </footer>
    </main>
    <!-- 新建编辑场地 -->
    <el-dialog :title="clubSiteTitle" :visible.sync="clubSiteVisible" width="30%" v-if='clubSiteVisible' :close-on-click-modal='false'>
      <el-form :model="clubSiteForm" :rules="rules" ref='clubSiteForm' label-width="100px">
        <el-form-item label="场地名称:" prop="Name">
          <el-input v-model="clubSiteForm.Name" show-word-limit maxlength="20" placeholder="请输入训练场地名称"></el-input>
        </el-form-item>
        <el-form-item label="训练地址:" prop="Address" class="Address">
          <el-input v-model="clubSiteForm.Address" placeholder="请搜索训练场地">
            <i slot="suffix" @click="changeInnerVisible" class="el-input__icon el-icon-map-location"></i>
          </el-input>
        </el-form-item>
        <el-form-item class="Longitude">
          <el-row>
            <el-col :span="11">
              <span>地址经度:</span>
              <span class="no-number">{{clubSiteForm.Longitude}}</span>
            </el-col>
            <el-col :span="11" :offset="1">
              <span>地址纬度:</span>
              <span class="no-number">{{clubSiteForm.Latitude}}</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="容纳人数:" prop='MemberCount' placeholder="可容纳人数">
          <el-input type='number' v-model="clubSiteForm.MemberCount" class="no-number"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="clubSiteForm.Remarks" :autosize="{ minRows: 3 }" type="textarea" placeholder="请输入场地备注" show-word-limit maxlength="300" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clubSiteVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="updateClubSite()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog custom-class="dialog-body" width="50%" title="请选择地点" v-if="innerVisible" :visible.sync="innerVisible" :close-on-click-modal='false'>
      <mapContainer :select='select' @sure='sure' />
    </el-dialog>
  </div>
</template>

<script>
import { getClubSiteList, delClubSite, updateClubSite } from "@/service/organizational";
import pagination from '@/components/pagination/pagination.vue';
import mapContainer from '@/components/map/map.vue';
import { deepClone } from '@/utils/index';

const ClubSiteForm = { // 新增编辑场地的初始化数据
  Name: '',
  Address: '',
  MemberCount: 0,
  Longitude: 0,
  Latitude: 0,
  Remarks: ''
};

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: ''
};

export default {
  name: "Field",
  components: {
    pagination,
    mapContainer
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入场地名称'));
      }
      callback();
    }

    const validateAddress = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入训练地址名称'));
      }
      callback();
    }

    const validateMemberCount = (rule, value, callback) => {
      this.clubSiteForm.MemberCount = parseInt(this.clubSiteForm.MemberCount)
      if (this.clubSiteForm.MemberCount === 0 || this.clubSiteForm.MemberCount > 0) {
        return callback();
      }
      callback(new Error('容纳人数应该大于0'));
    }

    return {
      clubSiteForm: deepClone(ClubSiteForm),
      listQuery: deepClone(ListQuery),
      tableList: [], // 表格数据
      loading: false, // 加载状态
      total: 0,  // 表格总条数
      clubSiteTitle: '新增场地', // 新建编辑title
      clubSiteVisible: false, //  新建编辑场地弹窗状态
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Address: [{ required: true, validator: validateAddress, trigger: 'blur' }],
        MemberCount: [{ validator: validateMemberCount, trigger: 'blur' }],
      },
      innerVisible: false, // 地图容器状态
      select: {}
    };
  },
  created() {
    this.getClubSiteList();
  },
  methods: {
    /**
     * 获取场地列表数据
     */
    async getClubSiteList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubSiteList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.getClubSiteList();
    },

    /**
     * 重置
     */

    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubSiteList();
    },

    /**
     * 新增或者编辑
     */
    addOrEidtClubSite(row) {
      this.clubSiteVisible = true;
      this.$nextTick(() => {
        this.$refs.clubSiteForm.clearValidate();
      })
      if (row) {
        this.clubSiteForm = { ...row };
        this.select = {
          location: {
            lat: this.clubSiteForm.Latitude,
            lng: this.clubSiteForm.Longitude
          },
          name: this.clubSiteForm.Address + '111'
        };
        this.clubSiteTitle = '编辑场地';
      } else {
        this.clubSiteForm = deepClone(ClubSiteForm);
        this.clubSiteTitle = '新增场地';
      }
    },

    /**
     * 确定新增或者编辑
     */
    updateClubSite() {
      this.$refs.clubSiteForm.validate(async (valid) => {
        if (!valid) return;

        if (this.clubSiteTitle === '编辑场地') {
          await updateClubSite({
            Id: this.clubSiteForm.Id,
            Address: this.clubSiteForm.Address,
            Latitude: this.clubSiteForm.Latitude,
            Longitude: this.clubSiteForm.Longitude,
            MemberCount: this.clubSiteForm.MemberCount,
            Name: this.clubSiteForm.Name,
            Remarks: this.clubSiteForm.Remarks
          });
          this.$message.success('编辑成功');
        } else {
          await updateClubSite(this.clubSiteForm);
          this.$message.success('新建成功');
        }

        this.clubSiteVisible = false;
        this.getClubSiteList();
      })

    },

    /**
     * 删除
     */
    async deleteClubSite(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delClubSite({ Id: row.Id });
        this.$message.success('删除成功');
        this.getClubSiteList();
      } catch (err) { };

    },

    changeInnerVisible() {
      this.select.name = this.clubSiteForm.Address;
      this.innerVisible = true;
    },

    sure(select) {
      this.select = select;
      this.clubSiteForm.Address = select.name;
      this.clubSiteForm.Latitude = select.location && select.location.lat || 0;
      this.clubSiteForm.Longitude = select.location && select.location.lng || 0;
      this.innerVisible = false;
      this.$refs.clubSiteForm.validateField('Address');
    }
  },
};
</script>
<style lang="scss" scoped>
.el-icon-map-location {
  cursor: pointer;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.Address {
  margin-bottom: 0 !important;
}
.Longitude {
  // height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #a6b4bf;
  margin: 8px 0;
}
</style>
