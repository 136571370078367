<template>
  <div class="map-box">
    <div class="container" id="container">
    </div>
    <div id="myPageTop" class="myPageTop">
      <table>
        <tr>
          <td>
            <label>请输入关键字：</label>
          </td>
        </tr>
        <tr>
          <td>
            <input id="tipinput" />
          </td>
        </tr>
      </table>
    </div>
    <div class="map-select">
      <div class="detail">
        <div>当前选择地点：{{ `${locationDetail.name || ''}` }}</div>
        <div>经度：{{ locationDetail.location && locationDetail.location.lng || 0 }}</div>
        <div>纬度：{{ locationDetail.location && locationDetail.location.lat || 0 }}</div>
      </div>
    </div>
    <div class="map-sure" v-show='locationDetail.name'>
      <el-button type="primary" @click="sure()">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',
  props: {
    select: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      locationDetail: {},
      map: null,
      marker: null
    }
  },
  mounted() {
    this.locationDetail = this.select;
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
      });

      if (this.locationDetail.location && this.locationDetail.location.lat && this.locationDetail.location.lng) {
        this.showInfoClick({ lnglat: this.locationDetail.location }, true);
      }

      //输入提示
      let autoOptions = {
        input: "tipinput"
      };
      let auto = new AMap.Autocomplete(autoOptions);
      let placeSearch = new AMap.PlaceSearch({
        map: this.map
      });  //构造地点查询类
      const select = (e) => {
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name);  //关键字查询查询
      };

      AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发

      AMap.event.addListener(placeSearch, "markerClick", (e) => {
        this.showInfoClick(e.event);
      });//注册监听，当选中某条记录时会触发

      this.map.on('click', this.showInfoClick);
    },

    regeocoder(loc) { //逆地理编码
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all"
      });
      geocoder.getAddress(loc, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          this.geocoder_CallBack(result);
        }
      });
    },

    geocoder_CallBack(data) {
      let address = data.regeocode.formattedAddress; //返回地址描述
      this.locationDetail.name = address;
      this.$forceUpdate();
    },


    // 点击确定
    sure() {
      this.$emit('sure', this.locationDetail);
    },

    // 点击触发
    showInfoClick(e, flag) {
      this.marker && this.map.remove(this.marker);
      this.marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [e.lnglat.lng, e.lnglat.lat]
      });
      if (!flag) {
        this.regeocoder([e.lnglat.lng, e.lnglat.lat]);
      }
      this.locationDetail.location = e.lnglat;
      this.map.add(this.marker);
      this.map.setFitView();
    }
  },
}
</script>

<style lang='scss' scoped>
.map-box {
  position: relative;
  height: 500px;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // width: 100%;
  // height: 100%;
}

.button-group {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 12px;
  padding: 10px;
}

.button-group .button {
  height: 28px;
  line-height: 28px;
  background-color: #0d9bf2;
  color: #fff;
  border: 0;
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  margin-bottom: 4px;
  cursor: pointer;
}
.button-group .inputtext {
  height: 26px;
  line-height: 26px;
  border: 1px;
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  margin-bottom: 4px;
  cursor: pointer;
}

// #tip {
//   background-color: #fff;
//   padding-left: 10px;
//   padding-right: 10px;
//   position: absolute;
//   font-size: 12px;
//   right: 10px;
//   top: 20px;
//   border-radius: 3px;
//   border: 1px solid #ccc;
//   line-height: 30px;
// }

.amap-info-content {
  font-size: 12px;
}

.myPageTop {
  position: absolute;
  top: 5px;
  left: 10px;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  margin: 10px auto;
  padding: 6px;
  font-family: "Microsoft Yahei", "微软雅黑", "Pinghei";
  font-size: 14px;
}
.myPageTop label {
  margin: 0 20px 0 0;
  color: #666666;
  font-weight: normal;
}
.myPageTop input {
  width: 170px;
}
.myPageTop .column2 {
  padding-left: 25px;
}

.map-select {
  position: absolute;
  background: #fff;
  width: 178px;
  top: 70px;
  left: 10px;
  border: 1px solid #ccc;
  margin: 10px auto;
  padding: 6px;
  font-family: "Microsoft Yahei", "微软雅黑", "Pinghei";
  font-size: 14px;
  .detail {
    display: flex;
    flex-direction: column;
  }
}

.map-sure {
  position: absolute;
  top: 5px;
  right: 10px;
}
</style>